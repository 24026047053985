import axios from "axios";

export default {
  namespaced: true,
  state: {
    allCourses: [],
    allCoursesCourses: [],
  },
  mutations: {
    // SET_ALL_COURSER(state, value) {
    //   state.courserUser = value
    // },
  },
  getters: {},
  actions: {
    // Funcionando
    async GET_ALL_COURSER({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlNodeTemplates}/academy`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.allCourses = data.body;
          state.allCoursesCourses = data.body.courses.courses;
          return { success: true, data: data };
        }
      } catch (err) {
        console.error(`Error GET_ALL_COURSER`);
        return { success: false, data: err.response };
      }
    },
    async CREATED_COURSE({ rootState, dispatch }, params) {
      // console.log('Parametos', params)
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlNodeTemplates}/academy`,
          headers: rootState.configAxios.headers,
          data: {
            idCourse: params.idCourse,
            idVideo: params.idVideo,
            idSection: params.idSection,
            state: params.state,
          },
        });
        if (data) {
          await dispatch("GET_ALL_COURSER");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
  },
};
