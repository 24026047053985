<template>
  <el-dialog
    title="Método de pago"
    :visible.sync="visiblePaymentMethods"
    center
    width="550px"
    :modalAppendToBody="false"
  >
    <div class="pos__payment_methods">
      <div class="w-full flex justify-center items-center">
        <div
          class="w-full max-w-[380px] grid grid-cols-2 items-center justify-center"
        >
          <div
            class="mx-auto"
            :class="`payment_method ${method.id} ${
              method.id === selectedMethod ? 'selected' : ''
            }`"
            v-for="method in paymentMethods"
            :key="`method_${method.id}`"
            @click="selectMethod(method)"
          >
            <span class="soon" v-if="method.id === 'credit_card'">Pronto!</span>
            <div :is="method.component" />
            <h3>{{ method.label }}</h3>
          </div>
        </div>
      </div>
      <div class="payment_methods__content" v-if="selectedMethod == 'cash'">
        <br />
        <div class="input_area" v-if="currentChannel === 4">
          <p>Dinero recibido:</p>
          <money
            v-model="moneyPaid"
            v-bind="money"
            placeholder="Efectivo recibido"
            class="el-input__inner"
          ></money>
        </div>
        <div class="input_area">
          <p>Valor de la venta:</p>
          <money
            :value="total"
            v-bind="money"
            placeholder="Valor de la venta"
            class="el-input__inner"
          ></money>
        </div>
        <div v-if="currentChannel !== 4"></div>
        <div v-if="currentChannel !== 4"></div>
        <div class="input_area" v-if="currentChannel === 4">
          <p>Dinero a devolver:</p>
          <p class="lacks_money" v-if="moneyPaid - total < 0">Falta dinero</p>
          <p v-else>{{ (moneyPaid - total) | currencyToValueMoney }}</p>
        </div>
        <div class="content__action">
          <el-button type="primary" @click="createOrder" :loading="loading">
            Finalizar Venta
          </el-button>
        </div>
      </div>
      <section
        class="payment_methods__content content__pay_link"
        v-if="selectedMethod == 'pay_link'"
      >
        <p>
          Puedes usar este enlace para enviárselo a tu cliente y que el decida
          como pagar.
        </p>
        <div :class="{ pay_link_actions: true, actived: newOrder }">
          <div class="inputcopy w-full">
            <input
              ref="urlreferredcopy"
              v-model="payLink"
              type="text"
              autocomplete="off"
              placeholder="Url de pago"
              class="urlreferredcopy el-input__inner w-full"
            />
            <div id="buttoncopy" class="btn" @click="copyLink()">
              <p v-if="copied">Copiar</p>
              <p v-else>Copiado</p>
            </div>
          </div>
        </div>
        <transition>
          <el-button
            v-if="!newOrder"
            type="primary"
            @click="createPayLink"
            :loading="!stateGenerate"
          >
            {{ stateGenerate ? "Generar enlace de venta" : "Generando link" }}
          </el-button>
        </transition>
      </section>
    </div>
  </el-dialog>
</template>

<script>
import Cash from "@/icons/cash";
import CreditCard from "@/icons/credit_card";
import PayLink from "@/icons/pay_link";
import whatsapp from "@/icons/whatsapp";
import { mapState } from "vuex";
export default {
  components: {
    Cash,
    CreditCard,
    PayLink,
    whatsapp,
  },
  props: ["visible"],
  data() {
    return {
      paymentMethods: [
        {
          id: "cash",
          label: "Efectivo",
          component: "Cash",
        },
        // {
        //   id: "credit_card",
        //   label: "Tarjeta de crédito",
        //   component: "CreditCard",
        // },
        {
          id: "pay_link",
          label: "Enlace de pago",
          component: "PayLink",
        },
      ],
      loading: false,
      selectedMethod: "cash",
      newOrder: null,
      copied: true,
      moneyPaid: 0,
      stateGenerate: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: " ",
        precision: 0,
        masked: false,
      },
    };
  },
  computed: {
    ...mapState(["storeData"]),
    ...mapState("pos", ["currentCustomer"]),
    currentChannel() {
      return this.$store.state.pos.currentChannel;
    },
    visiblePaymentMethods: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
    subtotal() {
      return this.$store.getters["pos/subtotal"];
    },
    total() {
      return this.$store.getters["pos/total"];
    },
    payLink() {
      if (this.newOrder) {
        const payLink = `${this.storeData.id}.${this.currentCustomer.id}.${this.newOrder.id}`;
        return `https://checkout.komercia.co/?pay_link=${payLink}`;
      } else {
        return `https://checkout.komercia.co/?pay_link=0.0.0`;
      }
    },
  },
  methods: {
    selectMethod(method) {
      this.selectedMethod = method.id;
    },
    copyLink() {
      this.copyTestingCode();
      this.copied = false;
    },
    copyTestingCode() {
      let testingCodeToCopy = this.$refs.urlreferredcopy;
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        // console.log(msg);
      } catch (err) {
        console.log(err);
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    // facebooklink(value) {
    //   if (value === "msg") {
    //     FB.ui({
    //       method: "send",
    //       link: this.payLink,
    //     });
    //   } else {
    //     FB.ui({
    //       method: "feed",
    //       caption: "Enlace de pago",
    //       description: "Este es el enlace para que generes el pago de tu orden",
    //       link: this.payLink,
    //     });
    //   }
    // },
    async createOrder() {
      this.loading = true;
      // const { successProduct } = await this.$store.dispatch(
      //   "pos/VERIFY_PRODUCTS"
      // );
      // if (successProduct) {
      if (this.moneyPaid >= this.total || this.currentChannel !== 4) {
        const { success, data } = await this.$store.dispatch(
          "pos/CREATE_ORDER",
          {
            paymentMethod: "6",
          }
        );
        if (success && data?.status === 201) {
          this.loading = false;
          this.visiblePaymentMethods = false;
          this.$notify({
            title: "Creada correctamente!",
            message: "La compra se ha realizado, puedes revisar en tus ventas",
            type: "success",
          });
        } else {
          this.loading = false;
          this.visiblePaymentMethods = false;
          this.$notify({
            title: "Falta de inventario",
            message: "Un producto esta agotado, intente de nuevo.",
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "Warning",
          message:
            "El dinero recibo tiene que ser igual o mayor al valor total de la compra",
          type: "warning",
        });
        this.loading = false;
      }
      // } else {
      //   this.$notify({
      //     title: "Error de api",
      //     message: "Error al verificar el stock de los productos",
      //     type: "error",
      //   });
      // }
    },
    async createPayLink() {
      this.stateGenerate = false;
      const { success, data } = await this.$store.dispatch("pos/CREATE_ORDER", {
        respond: false,
      });
      if (success && data.status === 201) {
        this.newOrder = data.data;
      } else {
        this.visiblePaymentMethods = false;
        this.$notify({
          title: "Falta de inventario",
          message: "La cantidad no puede ser mayor al stock actual",
          type: "error",
        });
      }
      this.this.stateGenerate = true;
    },
  },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return "Producto sin precio";
    },
    currencyToValueMoney(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.pos__payment_methods {
  display: grid;
  justify-content: center;
  grid-gap: 10px;
}

.payment_method {
  position: relative;
  width: 150px;
  /* height: 120px; */
  border: 1px solid #dcdfe6;
  padding: 15px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
  /* color: #606266; */
  cursor: pointer;
}
.payment_method.credit_card {
  cursor: not-allowed;
  border: 1px solid #dcdfe6 !important;
}
.payment_method.pay_link svg {
  width: 100%;
  margin-bottom: 10px;
}
.payment_method img {
  width: 120px;
}
.payment_method.selected {
  border: 2px solid royalblue;
}
.soon {
  position: absolute;
  left: 4px;
  top: 4px;
  font-size: 11px;
  background-color: #4429b4;
  color: #fff;
  border-radius: 5px;
  padding: 3px 5px;
}
.payment_methods__content {
  width: 480px;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-gap: 5px;
  grid-template-rows: 2fr auto auto 40px 3fr;
}
.payment_methods__content.content__pay_link {
  padding-top: 30px;
  grid-template-rows: initial;
  grid-gap: 20px;
  word-break: break-word;
}
.payment_methods__content.content__pay_link > div {
  display: flex;
}
.pay_link_actions {
  position: relative;
}
.pay_link_actions:not(.actived) * {
  opacity: 0.7;
  pointer-events: none;
}
/* .pay_link_actions:not(.actived)::after {
  content: "Te falta generar el enlace";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
} */
.input_area {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
}
.content__action {
  display: grid;
  grid-gap: 5px;
  align-self: end;
}
.lacks_money {
  color: red;
  font-weight: bold;
  font-size: 13px;
}
.pos__order__action {
  width: 100%;
  padding: 10px;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  background-color: #5c6af6;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
}
.pos__order__action:hover {
  opacity: 0.85;
}
.pos__order__action.action_link {
  background-color: #12cc6f;
}

.inputcopy {
  border: 1px solid #dddddd;
  display: grid;
  grid-template-columns: 1fr 80px;
  border-radius: 5px;
  max-width: 480px;
}
.urlreferredcopy:focus {
  outline: 0px;
}
#buttoncopy {
  border: none;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-content: center;
  color: green;
  font-weight: 600;
}
#buttonsend {
  border: none;
  height: 40px;
  width: 80px;
  background-color: green;
  border-radius: 5px;
  cursor: pointer;
}
.content-url {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 500px 100px;
  grid-gap: 10px;
}
.urlreferred {
  border: 0.5px solid #c5c0c0;
  height: 40px;
  width: 400px;
  border-radius: 5px;
}
.urlreferredcopy {
  height: 40px;
  /* width: 400px; */
  border: none;
}
.contentsocial {
  width: 500px;
  height: 500px;
  background-color: #ffffff;
}
.iconsmedia {
  display: grid;
  grid-template-columns: repeat(4, 50px);
  justify-items: center;
}
.fab {
  background-color: #0084ff;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
.fas {
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 30px;
  /* border:1px solid black; */
  cursor: pointer;
}
.fa-times {
  margin: 5px;
}
.fa-facebook-f {
  background-color: #074f93;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  /* border:1px solid #c4c4c4; */
}
.fa-twitter {
  background-color: #0084ff;
  font-size: 25px;
  /* border:1px solid #c4c4c4; */
  color: #fff;
}
.fa-facebook-messenger {
  color: #fff;
}
.whatsapp {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  cursor: pointer;
}
</style>
