<template>
  <el-dialog
    title="Exportar Suscriptores"
    @open="openDialog"
    :visible.sync="dialogVisible"
    width="50%"
    :modalAppendToBody="false"
  >
    <div>
      <hr class="separator" />
      <div class="export_class" v-if="tabSelected === 'export'">
        <p>
          <strong>Descarga</strong> un listado de tus suscriptores. Puedes
          descargarlo en formato <strong>CSV</strong>
        </p>
        <div class="export__options">
          <button
            class="btn_export"
            @click="exportSubscribers"
          >
            Exportar en CSV
          </button>
          <!-- <download-excel
            class="download_excel"
            :data="listData"
            :fields="objSale"
            type="csv"
            :name="`Clientes-${new Date()}.csv`"
          >
            <el-button size="small">Exportar en CSV</el-button>
          </download-excel> -->
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "SuscriptoresExport",
  props: {
    visible: Boolean,
    suscriptores: Array,
    tabAction: String,
  },
  data() {
    return {
      objSale: {
        "email-suscriptor": "email-suscriptor",
        "created_at-cliente": "created_at-cliente",
      },
      loading: true,
      listData: [],
    };
  },
  computed: {
    tabSelected: {
      get() {
        return this.tabAction;
      },
      set(newValue) {
        this.$emit("update:tabAction", newValue);
      },
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
    cities() {
      return this.$store.state.cities;
    },
  },
  methods: {
    async getClientes() {
      let listVentas = [];
      let objSale = {
        "email-suscriptor": "",
        "created_at-cliente": "",
      };
      this.suscriptores.forEach((subs) => {
        // console.log(suscriptoresFilter)
        objSale["email-suscriptor"] = subs.email;
        objSale["created_at-cliente"] = subs.created_at;
        listVentas.push({ ...objSale });
        // console.log(this.getBarrio(JSON.parse(sale.direccion_entrega)))
      });
      this.listData = listVentas;
      this.loading = false;
    },
    openDialog() {
      this.getClientes();
    },
    async exportSubscribers() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${this.$configKomercia.urlKomerciaAWS}/api/v1/subscribers/${this.$store.state.storeData.id}/export?type=csv`,
          headers: this.$store.state.configAxios.headers,
        });
        if (data) {
          window.open(
            `${this.$configKomercia.urlKomerciaAWS}/api/v1/subscribers/${this.$store.state.storeData.id}/export?type=csv`,
            "_blank"
          );
        }
      } catch (err) {
        console.log(err);
        return { success: false, data: err.response };
      }
    },
  },
};
</script>

<style scoped>
/* Import multiples product in modal */
.import {
  display: grid;
  /* grid-template: auto auto / auto auto; */
  justify-content: center;
  justify-items: center;
}
.import__desc {
  grid-column: 1 / span 2;
}
.import__action {
  display: grid;
  justify-content: end;
}
.export__action {
  margin-bottom: 10px;
  cursor: pointer;
}
.export_class {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-gap: 10px;
}
.export__options {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 10px;
}
.separator {
  margin-bottom: 20px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.el-button--primary {
  margin-top: 20px;
}
.import div {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .el-radio-group {
    display: flex;
    justify-content: center;
  }
  .import {
    display: flex;
    /* grid-template: auto auto / auto auto; */
    justify-content: center;
    justify-items: center;
    width: 100%;
    flex-direction: column;
  }
  .import .info-import {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .import .upload-demo {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
  }
}
.btn_export {
  @apply mt-5 border rounded-md border-indigo-700 text-indigo-700 px-5 py-2 cursor-pointer;
}
.btn_export:hover {
  @apply bg-indigo-700 text-white;
}
</style>
